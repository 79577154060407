.wrapper {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.title {
  color: #f8965a;
  text-align: center;
  font-family: Work Sans;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}
.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.tab {
  color: #aaa;
  text-align: center;
  font-family: Work Sans;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-transform: capitalize;
}
.active {
  color: #f8965a;
  border-bottom: 1px solid #f8965a;
}
.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 23px 23px;
}
.imageWrapper {
  cursor: pointer;
  transition: transform 0.1s ease;
}
.imageWrapper:hover {
  transform: scale(1.03); /* Zoom in by 10% (adjust as needed) */
}
.image {
  display: block;
  width: 100%;
  height: 100%;
}
.fadeIn {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.fadeOut {
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .items {
    grid-template-columns: repeat(3, 1fr);
    gap: 13px 15px;
  }
}
@media only screen and (max-width: 520px) {
  .items {
    grid-template-columns: repeat(2, 1fr);
  }
}

.wrapper {
  max-width: 897px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 0 auto;
  padding: 50px 0;
}
.title {
  color: #f8965a;
  text-align: center;
  font-family: Work Sans;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}
.infoWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.info {
  color: #fff;
  font-family: Work Sans;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 187.5%;
  letter-spacing: 0.48px;
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.2);
  text-decoration-skip-ink: none;
  text-underline-offset: 3px;
  text-decoration-thickness: 0.5px;
}
.info a{
  color: #fff;
  font-family: Work Sans;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 2em;
  letter-spacing: 0.48px;
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.2);
  text-decoration-skip-ink: none;
  text-underline-offset: 3px;
  text-decoration-thickness: 0.3px;
  transition: color 0.3s ease; /* Added transition property */
}
.info a:hover {
  color: #f8965a;
}
.wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  transition: 0.3s;
}
.wrapperBg {
  background: #2c2a2b;
  box-shadow: 0 0 12px rgba(73, 73, 73, 0.4);
}
.header {
  max-width: 1441px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.navItems {
  display: flex;
  align-items: center;
  gap: 30px;
}
.navItem {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 0.85em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  letter-spacing: 1px;
}
.icon {
  display: none;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    background: #2c2a2b;
    box-shadow: 0 0 3px rgba(1, 1, 1, 0.4);
  }
  .navItems {
    flex-direction: column;
    gap: 40px;
    background: #2c2a2b;
    transform: translateX(250%);
    transition: 0.3s;
    position: fixed;
    top: 65px;
    right: 0;
    padding-top: 100px;
    max-width: 320px;
    width: 100%;
    min-height: 100vh;
  }
  .sidebar {
    transform: translateX(0);
    right: 0;
  }
  .icon {
    display: block;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
  }
}

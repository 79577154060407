.wrapper {
  max-width: 912px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 0 auto;
  padding: 50px 0;
}
.title {
  color: #f8965a;
  text-align: center;
  font-family: Work Sans;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}
.heading{
  color: #fff;
  font-family: Work Sans;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: 2em;
  word-break: break-all;
}
.notification{
  color: #f8965a;;
  font-family: Work Sans;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 2em;
  word-break: break-all;
}
.info {
  color: #fff;
  font-family: Work Sans;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 2em;
  word-break: break-all;
}

.highlight {
  color: #f8965a;
}
.container {
  display: grid;
  grid-template-columns: 1fr 0.9fr;
  gap: 60px;
  width: 100%;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  width: 100%;
  margin-top: 18px;
}
.input {
  color: #454545;
  font-family: Work Sans;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9em;
  border-radius: 12px;
  background: #d9d9d9;
  border: none;
  outline: none;
  padding: 10px;
  width: 100%;
  padding: 5px 10px;
}
.textArea {
  color: #454545;
  font-family: Work Sans;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 218.75%;
  border-radius: 9px;
  background: #d9d9d9;
  border: none;
  outline: none;
  width: 100%;
  padding: 5px 10px;
  min-height: 286px;
}
.button {
  padding: 3px 25px;
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9em;
  border-radius: 12px;
  background: #6c7888;
  cursor: pointer;
  transition: 0.3s;
}
.button:hover {
  transform: translateY(-3px);
}
.textContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.infoWrapper a{
  transition: color 0.3s; /* Smooth color transition over 0.3 seconds */
}
.infoWrapper a:hover {
  color: #f8965a; /* Set the text color to red on hover */
}
.socialContainer {
  display: flex;
  gap: 30px;
}
.label {
  color: #fff;
  font-family: Work Sans;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 12px;
}
.socials {
  display: flex;
  gap: 10px;
}
.socialIcon {
  cursor: pointer;
  transition: 0.3s;
}
.socialIcon:hover {
  transform: translateY(-3px);
}
@media only screen and (max-width: 991px) {
  .container {
    grid-template-columns: 1fr;
    gap: 60px;
  }
}
@media only screen and (max-width: 640px) {
  .textArea {
    min-height: 200px;
  }
  .socials {
    gap: 8px;
  }
  .socialContainer {
    flex-direction: row;
    gap: 30px;
  }
  .info {
    font-size: 16px;
  }
  .input {
    padding: 3px 10px;
  }
  .button {
    font-size: 15px;
  }
}

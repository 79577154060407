.image {
  width: 100%;
  object-fit: cover;
  object-position: center;
  min-height: 220px;
  height: 300px;

  overflow: hidden;

  display: block;
  position: relative;
  z-index: -1;
}
@media only screen and (max-width: 767px) {
  .image {
    height: auto;
  }
}

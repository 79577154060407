.wrapper {
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: translate3d(0, 0, 0);
}
.image {
  display: none;
  width: 100%;
  object-fit: cover;
  object-position: left;
  min-height: 308px;
  max-height: 100vh;
}
.detailsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1441px;
  width: 90%;

  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
  z-index: 1;
}
.logo {
  max-width: 492.263px;
}
.mobileLogo {
  display: none;
}
.socialContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.label {
  color: #fff;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 8px;
}
.socials {
  display: flex;
  gap: 10px;
}
.socialIcon {
  width: 35px;
  height: 35px;
  cursor: pointer;
  transition: 0.3s;
  object-fit: cover;
}
.socialIcon:hover {
  transform: translateY(-3px);
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    height: auto;
  }
  .video {
    display: none;
  }
  .image {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .detailsContainer {
    bottom: 35px;
  }
  .logo {
    max-width: 360px;
  }
}
@media only screen and (max-width: 640px) {
  .image {
    max-height: 300px;
  }
  .detailsContainer {
    position: static;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 40px;
    transform: none;
    padding-top: 50px;
  }
  .label {
    display: none;
  }
  .socials {
    gap: 8px;
  }
  .socialContainer {
    flex-direction: row;
    gap: 8px;
  }
  .logo {
    display: none;
  }
  .mobileLogo {
    display: block;
    max-width: 300px;
    width: 100%;
  }
}

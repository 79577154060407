.wrapper {
  max-width: 912px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 0 auto;
  padding: 50px 0;
}

.title {
  color: #f8965a;
  text-align: center;
  font-family: Work Sans;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}
.image {
  display: block;
  max-width: 548px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.info {
  color: rgb(228, 228, 228);
  text-align: center;
  font-family: Work Sans;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.48px;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 20px;
}
.partnerWrapper {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.partners {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px 50px;
}
.partners img {
  opacity: 0.7;
}
@media only screen and (max-width: 767px) {
  .partners {
    display: flex;
    flex-direction: column; /* Change from 'row' to 'column' */
    align-items: center;
    justify-content: center;
    gap: 40px; /* Adjust the gap as needed */
  }
}